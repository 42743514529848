.form {
	width: 360px;
	margin: 20px auto 40px;

	@media (min-width: 768px) {
		width: 500px;
	}
	.error {
		border: 1px solid red;
	}
	.error-msg {
		text-align: center;
		color: red;
	}
}
.form-title {
	text-align: center;
	margin-bottom: 20px;
	text-transform: capitalize;
	color: blue;
}
.form-control {
	margin-bottom: 20px;
}
input,
textarea {
	width: 100%;
	padding: 8px;
}
textarea {
	height: 70px;
}
.btn-form-submit {
	margin-top: 15px;
	width: 100%;
	background-color: rgb(85, 85, 196);
	&:hover {
		background-color: rgb(117, 117, 203);
		color: #fff;
	}
	@media (min-width: 768px) {
		//max-width: 440px;
	}
}
