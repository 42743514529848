.card {
	display: grid;
	grid-template-rows: auto 1fr;
	min-width: 250px;

	margin-left: 40px;
	margin-right: 40px;

	border: 1px solid grey;
	border-radius: 5px;

	@media (min-width: 654px) {
		width: 100%;
		margin-left: 0px;
		margin-right: 0px;
	}
	img {
		height: 250px;
		object-fit: cover;
	}
	&-body {
		padding: 15px;
		.address {
			padding-top: 5px;
			padding-bottom: 5px;
			color: blue;
		}
	}

	&:hover {
		cursor: pointer;
		box-shadow: 0px 0px 10px grey;
	}
}

.card-footer {
	display: flex;
	justify-content: space-between;
}
