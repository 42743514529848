// Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans|Roboto:400,700&display=swap");

// Variables
:root {
	--background-color: #f1f5f8;
	--primary-color: hsl(185, 62%, 45%);
	--primary-color-light: hsl(185, 55%, 76%);
	--sm: 768px;
}

// Global styles
*,
::after,
::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: "Open Sans", sans-serif;
	line-height: 1.5;
	background-color: var(--background-color);
}
img {
	width: 100%;
	display: block;
}
ul {
	list-style-type: none;
}
a {
	text-decoration: none;
}
h1,
h2,
h3,
p {
	margin: 0;
	padding: 0;
}
.page-section {
	margin: 25px auto;
	padding: 15px 20px;
	width: 90vw;
	max-width: 1170px;
}
.btn {
	cursor: pointer;
	border: 1.5px solid var(--primary-color);
	letter-spacing: 1.5px;
	padding: 8px;
	background-color: var(--primary-color);
	border-radius: 5px;
	color: #fff;
	transition: all 0.5s;
	// display: block;

	&:hover {
		background-color: var(--primary-color-light);
		color: black;
	}
}
