.ad-create {
	margin-top: 50px;
	display: flex;

	gap: 20px;
	justify-content: center;

	.sell-btn,
	.rent-btn {
		display: block;
		border: 1px solid black;
		width: 40vw;
		padding-top: 10px;
		padding-bottom: 10px;
		cursor: pointer;
	}
	.btn-container {
		width: 40vw;
		margin-top: 40px;
		display: flex;
		justify-content: center;
		gap: 20px;
		button {
			width: 20vw;
			padding-top: 10px;
			padding-bottom: 10px;
			cursor: pointer;
		}
	}
}
