.ad-info {
	h3,
	h4,
	p {
		margin-bottom: 15px;
	}
	.footer {
		max-width: 350px;
	}
	.map-marker {
		color: rgb(72, 128, 180);
		font-size: 20px;
		margin-right: 8px;
	}
}
