.card-container {
	display: grid;
	gap: 1rem 2rem;
	margin-bottom: 25px;
	@media (min-width: 654px) {
		grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
		grid-auto-rows: minmax(200px, auto);
	}
}
.action {
	background-color: rgb(106, 165, 224);
	color: #fff;
	text-align: center;
	padding: 10px;
	margin-bottom: 20px;
	text-transform: capitalize;
}
.show-more {
	text-align: right;
	margin-bottom: 20px;
	span {
		margin-right: 15px;
		padding: 8px;
		background-color: aquamarine;
		border-radius: 5px;
	}
}
