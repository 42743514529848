.show-more-cat {
	text-align: right;
	margin-bottom: 20px;
	span {
		margin-right: 15px;
		padding: 8px;
		background-color: aquamarine;
		border-radius: 5px;
		cursor: pointer;
	}
}
