.image-upload {
	display: flex;

	align-items: center;
	label {
		margin-right: 7px;
	}
}
.avatar {
	cursor: pointer;
	margin-right: 8px;
}
