.info-app {
	display: flex;
	align-items: center;
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: rgba(40, 37, 37, 0.5);
	h2 {
		color: white;
		width: 100%;
		text-align: center;
		padding: 15px 8px;
	}
	button {
		display: grid;
		place-content: center;
		position: absolute;
		right: 25px;
		top: 25px;
		width: 40px;
		height: 40px;
		border-radius: 20px;
		border: 2px solid white;
		cursor: pointer;
	}
}
