.spinner-container {
	display: grid;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	place-content: center;
}

.spinner {
	height: 90px;
	width: 90px;
}
.spinner-small {
	height: 35px;
	width: 35px;
}
