.form-group {
	margin-bottom: 10px;
	@media screen and (min-width: 768px) {
		display: grid;
		grid-template-columns: 150px 1fr;
	}
	.form-label {
		display: block;
		margin-bottom: 5px;
	}
	.form-input {
		width: 100%;
		height: 30px;
		padding-left: 7px;
	}
	.form-control {
		display: flex;
		flex-direction: column;
	}
	.error-message {
		background-color: rgb(239, 101, 101);
		color: #fff;
		padding: 4px;
		font-size: 14px;
	}
}
