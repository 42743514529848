.nav {
	display: flex;
	justify-content: space-around;
	background-color: rgb(86, 167, 241);
	padding: 20px;
	&-user {
		cursor: pointer;

		font-size: 16px;
		&-info {
			display: flex;
			gap: 8px;
			align-items: center;
		}
	}
	&-dropdown {
		position: absolute;
		z-index: 9999;
		background-color: rgb(86, 167, 241);
		li {
			border-bottom: 2px solid white;
			padding: 7px;
		}
	}
}
.nav a.active {
	color: #fff;
}
